.add-modal .ant-modal-header {
    /* background: #1C2F42; */
    background: #1E9AF1;
} 
.add-modal .ant-modal-title, .add-modal .ant-modal-close {
    color: #ffffff;
}
.ant-modal-content { 
    border-radius: 6px;
}

.green-btn{
    background-color: #35B346;
    color: white;
}

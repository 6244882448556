.chart-scope .ant-radio-button-wrapper {
    width: 33%;
}

.picker-panel{
    position: absolute;
    z-index: 9999;
}

.picker-panel .year:hover {
    color: #000;
}
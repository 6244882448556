.error-picture {
    height: 20%;
    width: 20%;
    margin-top: 200px;
}

.error-title {
    font-size: 50px;
    font-weight: 600;
    margin-top: 50px;
}

.error-btn {
    border: 1px solid #F51C29;
    background-color: #F51C29;
    color: #fff;
}

.error-btn:hover {
    border: 1px solid #F51C29;
    background-color: #fff;
    color: #F51C29;
}

.error-lost {
    height: 100%;
    width: 100%;
    margin-top: 120px;
}
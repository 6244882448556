.content {
  margin: 8px 8px;
}

@media (min-width: 575.98px) {
  .content {
    margin: 20px 16px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 767.98px) {
  .content {
    margin: 20px 24px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) {
  .content {
    margin: 20px 32px;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) {
  .content {
    margin: 20px 40px;
  }
}

/* Extra large devices */
@media (min-width: 1599.98px) {
  .content {
    margin: 20px 48px;
  }
}
.pageHeader .content {
  margin-bottom: 0;
}

/* @media screen and (max-width: 767px) {
  .content {
    margin: 24px 0 0;
  }
} */

.pageHeader {
  background: #ffffff;
  border-bottom: 1px solid #e8e8e8;
}
.pageHeader .detail {
  display: -webkit-flex;
  display: flex;
}

.pageHeader .row {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.pageHeader .breadcrumb {
  margin-bottom: 16px;
}

.pageHeader .tabs {
  margin: 0 0 -17px -8px;
}
.pageHeader .tabs .ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
}

.pageHeader .contentTitle {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 12px;
}

.pageHeader .title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.pageHeader .action {
  margin-left: 56px;
  min-width: 266px;
}

.pageHeader .action .ant-btn-group:not(:last-child),
.pageHeader .action .ant-btn:not(:last-child) {
  margin-right: 8px;
}

.pageHeader .action .ant-btn-group > .ant-btn {
  margin-right: 0;
}

.pageHeader .title,
.pageHeader .content {
  -webkit-flex: auto;
          flex: auto;
}

.pageHeader .action,
.pageHeader .extraContent,
.pageHeader .main {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
}

.pageHeader .main {
  width: 100%;
}

.pageHeader .title,
.pageHeader .action {
  margin-bottom: 16px;
}

.pageHeader .logo,
.pageHeader .content,
.pageHeader .extraContent {
  margin-bottom: 16px;
}

.pageHeader .action,
.pageHeader .extraContent {
  text-align: right;
}

.pageHeader .extraContent {
  margin-left: 88px;
  min-width: 242px;
}

.content > .pageHeaderContent h1 {
  font-size: 24px;
  font-weight: 500;
}

@media screen and (max-width: 1599px) {
  .pageHeader .extraContent {
    margin-left: 44px;
  }
}

@media screen and (max-width: 1199px) {
  .pageHeader .extraContent {
    margin-left: 20px;
  }
}

@media screen and (max-width: 991px) {
  .pageHeader .row {
    display: block;
  }

  .pageHeader .action,
  .pageHeader .extraContent {
    margin-left: 0;
    text-align: left;
  }
}

@media screen and (max-width: 575px) {
  .pageHeader .action .ant-btn-group,
  .pageHeader .action .ant-btn {
    display: block;
    margin-bottom: 8px;
  }
  .pageHeader .action .ant-btn-group > .ant-btn {
    display: inline-block;
    margin-bottom: 0;
  }
}

.add-modal .ant-modal-header {
    /* background: #1C2F42; */
    background: #1E9AF1;
} 
.add-modal .ant-modal-title, .add-modal .ant-modal-close {
    color: #ffffff;
}
.ant-modal-content { 
    border-radius: 6px;
}

.green-btn{
    background-color: #35B346;
    color: white;
}

.content {
  margin: 8px 8px;
}

@media (min-width: 575.98px) {
  .content {
    margin: 20px 16px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 767.98px) {
  .content {
    margin: 20px 24px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) {
  .content {
    margin: 20px 32px;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) {
  .content {
    margin: 20px 40px;
  }
}

/* Extra large devices */
@media (min-width: 1599.98px) {
  .content {
    margin: 20px 48px;
  }
}
.pageHeader .content {
  margin-bottom: 0;
}

/* @media screen and (max-width: 767px) {
  .content {
    margin: 24px 0 0;
  }
} */

.error-picture {
    height: 20%;
    width: 20%;
    margin-top: 200px;
}

.error-title {
    font-size: 50px;
    font-weight: 600;
    margin-top: 50px;
}

.error-btn {
    border: 1px solid #F51C29;
    background-color: #F51C29;
    color: #fff;
}

.error-btn:hover {
    border: 1px solid #F51C29;
    background-color: #fff;
    color: #F51C29;
}

.error-lost {
    height: 100%;
    width: 100%;
    margin-top: 120px;
}
.chart-scope .ant-radio-button-wrapper {
    width: 33%;
}

.picker-panel{
    position: absolute;
    z-index: 9999;
}

.picker-panel .year:hover {
    color: #000;
}
.ant-layout .ant-layout-header {
    padding: 0px 40px;
    background: #f51c29;
}

.top-menu {
    background-color: transparent;
    line-height: 60px;
}

.top-menu.ant-menu-horizontal > .ant-menu-item > a span{
    display: block;
    color: #fff;
}

.top-menu.ant-menu-horizontal {
    border: none;
}

.top-menu.ant-menu-horizontal .ant-menu-item-selected {
    border-bottom: 2px solid #fff;
    color: #fff;
}

.top-menu.ant-menu-horizontal .ant-menu-item:hover {
    border-bottom: 2px solid #fff;
}

.header-username {
    color: #fff;
    margin-left: 10px;
}

.ant-menu-vertical .ant-menu-item .header-username {
    color: #000
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Nunito Sans", sans-serif;
}

/*****************************/
/********** GLOBAL  **********/
/*****************************/

.text-center {
    text-align: center;
}

.loading {
    text-align: center;
    border-radius: 4px;
    padding: 30px 50px;
    margin: 20px 0;
    height: 90vh;
}

.fit {
    min-width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.hover-underline:hover {
    text-decoration: underline;
}

.asterisk-red::after {
    content: '*';
    color: red;
}

.asterisk-red-before::before {
    content: '*';
    color: red;
}

.anchors {
    color: #bfbfbf;
    margin-right: 5px;
}

.anchors:hover {
    color: gray;
}

.float-right {
    float: right;
}

.text-transform-capitalize {
    text-transform: capitalize;
}

.view-modal .ant-row {
    margin-bottom: 10px;
}

.color-red {
    color: red;
}

/********************************/
/********** OVER-RIDE **********/
/*******************************/

.ant-card-body,
.pageHeader,
.ant-card,
.ant-card-bordered {
    background-color: #fdfdfd;
}

.ant-select.ant-select-disabled .ant-select-selection {
    background: none;
    /* cursor: not-allowed; */
}

.ant-select-selection--multiple .ant-select-selection__choice {
    background-color: #1a98f0;
    border-radius: 5px;
    color: #fff;
}

.ant-form-item-label > label {
    font-weight: 600;
    color: #8e9cb0;
}

/* pending */
.ant-modal-body {
    padding: 24px;
}

/* Centers text for radio buttons */
.ant-radio-button-wrapper {
    text-align: center;
    background: none;
}


/* Styling for name column contents */
.name-link {
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.65);
    font-weight: 600;
    transition: all 0.3s;
}

.name-link:hover {
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 2px solid rgba(0, 0, 0, 0.8);
}

/* Reduces spacing between form items */
.ant-form .ant-form-item {
    margin-bottom: 8px;
}

.badge-style .ant-badge-status-dot {
    width: 5px;
    height: 5px;
    
}

/******** TABLES ********/

thead.ant-table-thead > tr > th {
    background: none;
    color: #a7b1c0;
}

.ant-table-body {
    overflow: auto;
}

.no-wrap-table td {
    white-space: nowrap;
}

.no-wrap-table th {
    white-space: nowrap;
}

.ant-table-pagination.ant-pagination {
    margin: 18px!important;
}

/******** PADDINGS ********/
.p-0{ padding: 0px }
.p-5{ padding: 5px }
.p-10{ padding: 10px }

.pt-0 { padding-top: 0 }
.pt-5 { padding-top: 5px }
.pt-10 { padding-top: 10px }

.pb-0{ padding-bottom: 0 }
.pb-5 { padding-bottom: 5px }
.pb-10 { padding-bottom: 10px }

.pl-0{ padding-left: 0 }
.pl-5 { padding-left: 5px }
.pl-10 { padding-left: 10px }

.pr-0{ padding-right: 0 }
.pr-5 { padding-right: 5px }
.pr-10 { padding-right: 10px }


/******** MARGINS ********/
.m-0{ margin: 0px }
.m-5{ margin: 5px }
.m-10{ margin: 10px }

.mt-0 { margin-top: 0 }
.mt-5 { margin-top: 5px }
.mt-10 { margin-top: 10px }

.mb-0{ margin-bottom: 0 }
.mb-5 { margin-bottom: 5px }
.mb-10 { margin-bottom: 10px }

.ml-0{ margin-left: 0 }
.ml-5 { margin-left: 5px }
.ml-10 { margin-left: 10px }

.mr-0{ margin-right: 0 }
.mr-5 { margin-right: 5px }
.mr-10 { margin-right: 10px }

.h-100 .ant-card-body {
    height: 454px;
    background-color: #fdfdfd;
}

/********************************/
/********* MEDIA QUERY *********/
/*******************************/

/* Small devices (landscape phones, less than 576px) */
@media (min-width: 575.98px) { 
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 767.98px) { 

}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) { 
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) { 
    
}









.ant-layout .ant-layout-header {
    padding: 0px 40px;
    background: #f51c29;
}

.top-menu {
    background-color: transparent;
    line-height: 60px;
}

.top-menu.ant-menu-horizontal > .ant-menu-item > a span{
    display: block;
    color: #fff;
}

.top-menu.ant-menu-horizontal {
    border: none;
}

.top-menu.ant-menu-horizontal .ant-menu-item-selected {
    border-bottom: 2px solid #fff;
    color: #fff;
}

.top-menu.ant-menu-horizontal .ant-menu-item:hover {
    border-bottom: 2px solid #fff;
}

.header-username {
    color: #fff;
    margin-left: 10px;
}

.ant-menu-vertical .ant-menu-item .header-username {
    color: #000
}
.pageHeader {
  background: #ffffff;
  border-bottom: 1px solid #e8e8e8;
}
.pageHeader .detail {
  display: flex;
}

.pageHeader .row {
  display: flex;
  width: 100%;
}

.pageHeader .breadcrumb {
  margin-bottom: 16px;
}

.pageHeader .tabs {
  margin: 0 0 -17px -8px;
}
.pageHeader .tabs .ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
}

.pageHeader .contentTitle {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 12px;
}

.pageHeader .title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.pageHeader .action {
  margin-left: 56px;
  min-width: 266px;
}

.pageHeader .action .ant-btn-group:not(:last-child),
.pageHeader .action .ant-btn:not(:last-child) {
  margin-right: 8px;
}

.pageHeader .action .ant-btn-group > .ant-btn {
  margin-right: 0;
}

.pageHeader .title,
.pageHeader .content {
  flex: auto;
}

.pageHeader .action,
.pageHeader .extraContent,
.pageHeader .main {
  flex: 0 1 auto;
}

.pageHeader .main {
  width: 100%;
}

.pageHeader .title,
.pageHeader .action {
  margin-bottom: 16px;
}

.pageHeader .logo,
.pageHeader .content,
.pageHeader .extraContent {
  margin-bottom: 16px;
}

.pageHeader .action,
.pageHeader .extraContent {
  text-align: right;
}

.pageHeader .extraContent {
  margin-left: 88px;
  min-width: 242px;
}

.content > .pageHeaderContent h1 {
  font-size: 24px;
  font-weight: 500;
}

@media screen and (max-width: 1599px) {
  .pageHeader .extraContent {
    margin-left: 44px;
  }
}

@media screen and (max-width: 1199px) {
  .pageHeader .extraContent {
    margin-left: 20px;
  }
}

@media screen and (max-width: 991px) {
  .pageHeader .row {
    display: block;
  }

  .pageHeader .action,
  .pageHeader .extraContent {
    margin-left: 0;
    text-align: left;
  }
}

@media screen and (max-width: 575px) {
  .pageHeader .action .ant-btn-group,
  .pageHeader .action .ant-btn {
    display: block;
    margin-bottom: 8px;
  }
  .pageHeader .action .ant-btn-group > .ant-btn {
    display: inline-block;
    margin-bottom: 0;
  }
}
